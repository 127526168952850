body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.center {
  margin: auto;
  /* width: 69%; */
}
a:hover {
  color: #d64f02;
}
.portfolio-token {
  color: #fff;
}

.strategies-button button {
  width: 9.32vw;
  min-width: 170px;
  height: 2.6vw;
  margin-left: 0;
  max-width: 179px;
  max-height: 50px;
}
.strategies-button:hover {
  color: #02d633;
  opacity: 0.8;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #d64f02; /* Цвет фона спиннера */
  border-top: 4px solid #3498db00; /* Цвет передней границы (создает эффект вращения) */
  border-radius: 50%;
  animation: spin 1s linear infinite; /* Анимация вращения */
  margin: auto;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.modal {
  background-color: transparent;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20%;
  z-index: 1100;
}
.up {
  position: fixed;
  right: 0;
  width: 3.02vw;
  top: 54.7vw;
  right: 2vw;
  z-index: 1000;
  cursor: pointer;
}

.hovering {
  transform-origin: center;
  transition: scale 3s ease-in-out;
  border: none;
}
.active {
  transform: scale(1.1);
}

.recharts-sector {
border: #02d633 solid 1px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
